.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  font-family: "Brutal Type", sans-serif;
  font-size: 32px;
  letter-spacing: 2px;
}

.container .unityWrapper {
  background: #000000;
  width: 1120px;
  height: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Change to column layout */
}

.container .unityWrapper .loadingBar {
  background: #111111;
  width: 500px;
  height: 5px;
  border-radius: 1px;
  overflow: hidden;
}

.container .unityWrapper .loadingBar .loadingBarFill {
  border-radius: 4px;
  height: 8px;
  background: #9AC9E3;
  transition: 0.3s width ease-out;
}

.container .unityWrapper .loadingText {
  overflow: hidden;
  margin-top: 10px; /* Add top margin to separate the text and loading bar */
  text-align: center; /* Center-align text horizontally */
}

.container .unityWrapper .loadingText p {
  color: #9AC9E3;
}

.container .unityWrapper canvas {
  width: 100%;
  height: 100%;
}

.container .screenshots {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.container .buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 800px
}

.container .fullScreen {
  color: white;
}
.container .fullScreen:hover {
  opacity: 0.7;
  cursor: pointer;
}
