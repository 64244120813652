* {
  box-sizing: border-box;
  font-family: 'Brutal Type', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}


body {
  background: #111111;
  padding: 0;
  margin: 0
}

.h1 {
  color: white;
}

.h2 {
  color: #fff;
}


@font-face {
  font-family: 'Brutal Type';
  src: local('/fonts/BrutalType/BrutalType-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: local('/fonts/BrutalType/BrutalType-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: local('/fonts/BrutalType/BrutalType-Bold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: local('/fonts/BrutalType/BrutalType-ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}
.Toastify .Toastify__toast {
  background-color: #081824;
  border-radius: 12px;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.3);
}

.Toastify .Toastify__toast-body {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.Toastify .Toastify__toast--default {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--warning {
  color: #ffe318;
}

.Toastify .Toastify__toast--success {
  color: #52ae6c;
}

.Toastify .toast-success-buy-ticket {
  margin-top: 100px;
  color: #5b5b7b;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  background-color: #ffffff;
}

.Toastify .Toastify__toast--info {
  color: #ecf4fb;
}

.Toastify .Toastify__toast--error {
  color: #c62828;
}

.Toastify .Toastify__close-button {
  color: white;
  align-self: center;
}
.Toastify .Toastify__close-button svg {
  height: 18px;
  width: 18px;
}