::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #0f2f45;
  border: solid 1px transparent;
  border-radius: 100vh;
}
